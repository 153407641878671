export const moduleIcons: any = {
  "User Management": "userManagement",
  "approval management": "approval",
  "Biller Management": "biller",
  "Gift Card Management": "giftCards",
  "Payroll Management": "payrollManagement",
  "Messaging Management": "messaging",
  "Customer Management": "customerAcc",
  "Business Management": "business",
  "Posting Management": "giftCards",
  "Fees, Interest & Charges": "business",
  "Reporting Management": "report",
  "Accounting Management": "accountOfficer",
  "ID Verification": "customerInfo",
  Logs: "customerAcc",
  "Merchant Verification": "business"

  //   icon: "customerInfo",

  //   icon: "business",

  //   icon: "report",

  //   icon: "business",

  //   icon: "giftCards",

  //   icon: "accountOfficer",

  //   icon: "accountOfficer"
};
