import styled from "styled-components";

import UnstyledButton from "../../library/UnstyledButton";

export const StyledDashboard = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  main {
    /* padding-left: 300px; */
    height: 100%;
    width: calc(100% - var(--sidebar-width));
    margin-left: auto;
    height: 100%;
  }
`;

export const StyledUser = styled(UnstyledButton)`
  display: flex;
  font-size: 1rem;
  color: hsl(216, 9%, 11%);
  line-height: 1.3;
  column-gap: 8px;

  .name {
    margin-right: 0.8rem;
    display: -webkit-inline-box;
    -webkit-box-orient: block-axis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    max-width: 17ch;
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #a8eeaa;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    font-size: 11px;
    line-height: 1.4;
    letter-spacing: 0.41px;
    text-transform: uppercase;
    color: hsl(135, 41%, 21%);
  }

  svg {
    height: 16px;
    width: auto;
  }
`;

export const StyledOptions = styled.div`
  --easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
  --duration: 0.2s;
  position: absolute;
  top: 1.5rem;
  right: 0;
  border-radius: 4px;
  z-index: 2;
  transform-origin: top right;
  visibility: hidden;
  transform: scale(0);
  border-bottom: 0.5px solid hsl(180, 9%, 98%);
  box-shadow: rgba(9, 10, 11, 0.02) 0px 4.1px 3.2px 0px,
    rgba(9, 10, 11, 0.03) 0px 8.3px 7px 0px,
    rgba(9, 10, 11, 0.04) 0px 10px 12.1px 0px,
    rgba(9, 10, 11, 0.054) 0px 16.8px 22.8px 0px;
  background: rgb(255, 255, 255);
  padding: 5px;

  transition: transform 0.2s cubic-bezier(0.16, 1, 0.3, 1), visibility 0s linear;
  li {
    /* border: 0.5px solid hsla(210, 11%, 89%, 1); */
  }

  button {
    padding: 10px;
  }
`;

export const StyledArticle = styled.article`
  padding: 3rem;
  position: relative;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  border-bottom: 1px solid #ebedef;
  height: 66px;
  padding: 0 3rem;

  .profile.show {
    ${StyledOptions} {
      visibility: visible;
      transform: scale(1);
      transition: transform 0.2s ease, visibility 0s linear;
    }
  }
`;
