import React, { Component, ErrorInfo, ReactNode } from "react";

import ErrorPage from "../views/ErrorPage";


interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  redirect?: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    redirect: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  public componentDidUpdate() {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }

  public render() {
    if (this.state.redirect || this.state.hasError) {
      return <ErrorPage />;
    } else {
    return this.props.children
    }
   
  }
}

export default ErrorBoundary;
