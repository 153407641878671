import styled, { css } from "styled-components";

import { ButtonProps } from "./Button.types";

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  transition: all 0.6s;
  justify-content: center;
  font-size: 1rem;
  line-height: 23px;
  border-radius: 8px;
  outline: none;
  text-align: center;
  letter-spacing: -0.12px;
  border: none;
  cursor: pointer;
  position: relative;

  &[aria-disabled="true"] {
    &,
    &:hover,
    &:active,
    &:focus-visible {
      opacity: 0.5;
      cursor: not-allowed;
      transform: none;
    }
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      padding: 8px 25px;
      font-size: 0.75rem;
      letter-spacing: -0.12px;
    `}

  ${(props) =>
    props.size === "md" &&
    css`
      padding: 13px 24px;
      min-inline-size: 200px;
    `}

  ${(props) =>
    props.variant === "outline" &&
    css`
      border: 1px solid hsl(122, 100%, 31%);
      color: hsl(216, 9%, 11%);
      background-color: hsl(107, 54%, 95%);
    `}

    ${(props) =>
    props.variant === "solid" &&
    css`
      background-color: hsla(135, 41%, 21%, 1);
      color: hsl(0, 0%, 100%);
      border: none;
    `}
    ${(props) =>
    props.variant === "text" && 
    css`
      background-color: transparent;
      color:  hsla(135, 41%, 21%, 1);
      border: none;
    `}

  ${(props) =>
    props.color === "secondary" &&
    css`
      border: none
      color: hsl(0, 0%, 100%);
      background: hsl(216, 9%, 11%);
    `}

  ${(props) =>
    props.color === "danger" &&
    css`
      color: hsl(0, 67%, 56%);
    `};
  ${(props) =>
    props.color === "warning" &&
    css`
      background: hsl(218, 7%, 42%);
      color: hsl(0, 0%, 100%);
    `};
  ${(props) =>
    props.color === "transparent" &&
    css`
      background: hsla(0, 0%, 100%, 1);
      color: hsla(216, 9%, 11%, 1);
      border: 1px solid hsla(210, 11%, 93%, 1);
      padding: 10px;
    `};

  /* loading indicator  */
  &::after {
    content: "";
    position: absolute;
    display: block;
    inline-size: 0.8rem;
    height: 0.8rem;
    top: calc(50% - 0.45rem);
    right: 0.5rem;
    transform: translate(calc(0.45 / 2 * 1rem));
    border: 3px transparent;
    border-top-color: currentColor;
    border-style: solid;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 250ms;
    box-sizing: border-box;
  }

  &[data-loading="true"] {
    pointer-events: none;

    &::after {
      opacity: 1;
      animation: rotate 750ms linear infinite;
    }
  }

  .icon-left {
    margin-right: 10px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledText = styled.span<ButtonProps>`
  /* ${(props) =>
    props.variant === "text" &&
    css`
      color: hsl(0, 67%, 56%);
    `} */
`;
