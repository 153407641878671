import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PublicRoute = ({
  children,
  isAuth,
}: {
  children: JSX.Element;
  isAuth: boolean | undefined;
}) => {
  const { state } = useLocation();

  return isAuth ? <Navigate to={state?.path || "/"} /> : children;
};

export default PublicRoute;
