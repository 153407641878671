interface IRouteMapType {
  [index: string]: string;
}

export const routeMap: IRouteMapType = {
  //user management modules
  users: "user-management/users",
  roles: "user-management/roles",
  functions: "user-management/functions",
  modules: "user-management/modules",

  //biller management modules
  billers: "biller-management/billers",
  "biller aggregators": "biller-management/aggregators",
  "bill items": "biller-management/bill-items",
  "biller categories": "biller-management/bill-categories",
  "biller configurations": "biller-management/configurations",
  "biller transactions": "biller-management/transactions",

  //gift card management modules
  "gift card providers": "giftcard-management/providers",
  "gift cards": "giftcard-management/giftcards",
  "gift card mapping": "giftcard-management/mapping",
  "gift card markup": "giftcard-management/markup",

  //
  "pending verification": "id-verification/pending-verification",

  //messaging management modules
  messages: "messaging-management/messages",
  "messaging providers": "messaging-management/providers",

  //log
  "audit trail": "logs/audit-trail",

  //payroll management modules
  "payroll component": "payroll-management/payroll-components",
  "generate payslip": "payroll-management/generate-payslip",
  "employee information": "payroll-management/employee-information",
  "schedule payroll": "payroll-management/schedule-payroll",

  //customer management
  "customer account": "customer-management/customer-account",
  "customer information": "customer-management/customer-information",
  "business account": "customer-management/business-account",
  "business information": "customer-management/business-information",

  //business management
  branch: "business-management/branch",
  "account officer": "business-management/account-officer",

  //reporting management
  "generate statement": "reporting-management/statement",

  //fees, inters & charges
  "advance fee": "fee-interest/advance-fee",
  charges: "fee-interest/charges",
  "cot fees": "fee-interest/cot-fee",
  interest: "fee-interest/interest",
  "loan fees": "fee-interest/loan-fee",

  //posting management
  "account balance": "posting/account-balance",
  "post transaction": "posting/post-transaction",
  "batch post transaction": "posting/batch-post-transaction",
  "reverse transaction": "posting/reverse-transaction",
  "posting transactions": "posting/posting-transactions",
  "gl transactions": "posting/gl-transactions",
  "customer transaction": "posting/nominal-transactions",
  "transaction category": "posting/transaction-category",
  "transaction tag": "posting/transaction-tag",

  //accounting management
  "gl account": "account-management/gl-account",
  "ifrs account": "account-management/ifrs-account",

  //merchant verification
  "document verification": "merchant-verification/document-verification"
};
