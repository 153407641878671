import { Player } from "@lottiefiles/react-lottie-player";

import loader from "./loader.json";

export const Loader = ({ ...rest }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Player
        src={loader}
        style={{ width: "150px", height: "150px" }}
        autoplay
        loop
      />
    </div>
  );
};
