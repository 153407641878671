import styled from "styled-components";

export default styled.button.attrs({
  type: "button",
})`
  cursor: pointer;
  background: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  border: none;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
