import styled, { css } from "styled-components";

import { IconProps } from "./Icon.types";
import { icons } from "./icons";

/**
 * An Icon is a piece of visual element, but we must ensure its accessibility while using it.
 * It can have 2 purposes:
 *
 * - *decorative only*: for example, it illustrates a label next to it. We must ensure that it is ignored by screen readers,
 * by setting `aria-hidden` attribute (ex: `<Icon icon="check" aria-hidden />`)
 * - *non-decorative*: it means that it delivers information.
 * For example, an icon as only child in a button. The meaning can be obvious visually,
 * but it must have a proper text alternative via `aria-label` for screen readers. (ex: `<Icon icon="print" aria-label="Print this document" />`)
 */

const Icon = ({ icon, size = 25, style, ...delegated }: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 25" style={style} {...delegated}>
      <title> {icon} </title>
      {icons[icon]}
    </Svg>
  );
};

export function getFill({ variant }: any) {
  return css`
    ${variant == "fill" &&
    css`
      fill: currentColor;
      stroke: none;
    `}

    ${variant == "stroke" &&
    css`
      stroke: currentColor;
      fill: none;
    `}
  `;
}

const Svg = styled.svg`
  display: inline-block;
  transform: translate3d(0, 0, 0);
  vertical-align: middle;
  shape-rendering: inherit;
  path {
    stroke-miterlimit: 10;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 1.5;
    ${getFill};
  }
`;

export default Icon;
