import styled from "styled-components";

export const StyledSidebar = styled.aside`
  width: var(--sidebar-width);
  /* min-height: 100vh; */
  height: 100%;
  background: var(--color-green-600);
  position: fixed;
  padding-block: 40px;
  padding-inline: 10px;
  overflow-y: auto;
  /* can scroll but hides the scroll bar on firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 5px;
  }

  .navbar-area {
    margin-top: 20px;
    overflow-y: auto;
    height: calc(100% - 50px);

    &::-webkit-scrollbar {
    display: none;
  }
`;

export const LogoWrap = styled.div`
  display: flex;
  justify-content: start;
  padding-left: 20px;

  svg {
    width: auto;
    height: 22px;
    position: fixed;
 }
`;
