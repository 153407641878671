import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const PrivateRoute = ({
  children,
  isAuth,
}: {
  children: JSX.Element;
  isAuth: boolean | undefined;
}) => {
  const location = useLocation();
  return !isAuth ? (
    <Navigate to="/signin" replace={true} state={{ path: location.pathname }} />
  ) : (
    children
  );
};

export default PrivateRoute;
