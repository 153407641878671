import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledNav = styled.li`
  + li {
    margin-top: 4px;
  }
`;

export const StyledNavItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding-block: 10px;
  padding-inline: 15px;
  transition: background 0.2s, color 0.2s;
  color: var(--color-white);
  /* background-color: var(--color-green-500); */
  column-gap: 14px;
  cursor: pointer;
  font-weight: 400;

  button {
    width: 30px;
    color: inherit;
  }
  &:hover {
    background: hsl(102.85, 53.84%, 94%);
    color: var(--color-green-500);
  }
  &.menuVisible {
    background-color: var(--color-green-500);
    /* color: var(--color-green-500); */
    &:hover {
      color: var(--color-white);
    }
    button {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const StyledMainLink = styled.span`
  color: inherit;
  display: flex;
  align-items: center;
  column-gap: 14px;
  font-size: calc(13 / 16 * 1rem);
  line-height: 1.5;
  letter-spacing: -0.12;
`;

export const StyledLabel = styled.span`
  color: inherit;
  font-size: calc(13 / 16 * 1rem);
  line-height: 1.5;
  letter-spacing: -0.12;
  text-transform: capitalize;
`;

export const StyledLink = styled(Link);

export const StyledSubLink = styled(NavLink)`
  display: flex;
  border-radius: 10px;
  padding-block: 10px;
  padding-inline: 50px 20px;
  /* margin-left: 28px; */
  /* background-color: var(--color-white); */
  color: var(--color-white);
  margin-top: 8px;
  &[aria-current="page"] {
    color: var(--color-green-500);
    background-color: var(--color-white);
  }
`;
