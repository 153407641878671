import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { toast } from "react-toastify";

import { deleteToken } from "./utils/tokenConfig";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVICE_URL
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("AppToken");

  return {
    headers: {
      ...headers,
      authentication: token
    }
  };
});

const logoutLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {
        toast.warn("Session is Expired");
        setTimeout(() => {
          location.reload();
        }, 600);
      }
    }
  }
  if (networkError) {
    if (networkError["statusCode"] == 401) deleteToken();
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, logoutLink, httpLink]),
  cache: new InMemoryCache(),
});
