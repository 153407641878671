import { createGlobalStyle } from "styled-components";

import AeonikBold from "../assets/fonts/Aeonik/Aeonik-Bold.otf";
import AeonikLight from "../assets/fonts/Aeonik/Aeonik-Light.otf";
import AeonikMedium from "../assets/fonts/Aeonik/Aeonik-Medium.otf";
import AeonikRegular from "../assets/fonts/Aeonik/Aeonik-Regular.otf";
import AeonikThin from "../assets/fonts/Aeonik/Aeonik-Thin.otf";

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Aeonik";
  src: url(${AeonikThin}) format(truetype);
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url(${AeonikLight}) format(truetype);
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url(${AeonikRegular}) format("opentype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url(${AeonikMedium}) format("opentype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik";
  src: url(${AeonikBold}) format("opentype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

*, *::after, *::before {
    box-sizing: border-box;
    margin:0;
  position:relative;
  }

* {
  transition: color .25s cubic-bezier(.25,.46,.45,.94),background-color .25s cubic-bezier(.25,.46,.45,.94);
}

html,
body {
  font-family: 'Aeonik', sans-serif;
  margin: 0;
  padding: 0;
}

button {
  font-family: 'Aeonik', sans-serif;
}

html,
body, #root {
  height: 100%;
}

a {
  text-decoration: none;
}

ul {
    list-style:none;
    padding:0;
  }

/* broken image styling */

img {
  position:relative;
}

img:before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        background-color:var(--color-green-400);
        border: 2px dotted hsl(120, 100%, 8.235294117647058%);
        border-radius: 8px;
        font-size: 0;
      }

      img:after {
        content: attr(alt);
        display: block;
        color: #ffffff;
        position: absolute;
        top: 10px;
        left: 0;
        width: 95%;
        right: 0;
        font-size:10px;
        text-align: center;
        word-break: break-word;
        line-height: 1;
      }


:root {
  --sidebar-width: 280px;
  --color-gray-400: hsl(219 8% 34%);
 --color-white: hsl(0, 0%, 100%);
 --color-black: 
hsl(0, 0%, 0%);
  --color-green-400:hsl(120, 100%, 26%);
  --color-green-600: hsl(135, 41%, 21%);
  --color-green-200: hsl(107, 54%, 85%);
  --color-gray-500:hsl(216, 9%, 11%);
  --color-gray-200: hsl(218, 8%, 52%);
  --color-green-800:hsl(122, 100%, 31%);
  --color-green-500: hsl(136, 40%, 26%);
 --color-gray-300: hsl(218, 7.8%, 42.9%);
 --toastify-font-family: inherit;
 --toastify-text-color-light: hsl(219, 8%, 34%);
}

.Toastify__toast-body {
  font-size:calc(14 / 16 * 1rem)
}



`;

export default GlobalStyles;
