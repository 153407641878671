import dayjs from "dayjs";

export const sessionInfo = {
  AppToken: "AppToken",
  AppUserData: "AppUserData",
  AppGoogleData: "AppGoogleData",
  AppTokenExpire: "AppTokenExpire",
  CurrentUser: "CurrentUser",
  FunctionData: "FunctionData",
  RoleMap: "RoleMap",
};

export const finishAuth = (
  token: string,
  userData: { [key: string]: any },
  expires_at: number,
  roleMap: any
) => {
  const date = dayjs.unix(expires_at);

  const functionArr: any[] = [];

  roleMap?.flatMap((module: any) =>
    module?.modules?.forEach((item: any) => {
      const functions = item?.functions;
      functionArr.push(...functions);
    })
  );


  localStorage.setItem(sessionInfo.RoleMap, JSON.stringify(roleMap))
  localStorage.setItem(sessionInfo.FunctionData, JSON.stringify(functionArr));
  localStorage.setItem(sessionInfo.AppToken, token);
  localStorage.setItem(sessionInfo.AppUserData, JSON.stringify(userData));
  localStorage.setItem(sessionInfo.AppTokenExpire, JSON.stringify(date));
  localStorage.setItem(
    sessionInfo.CurrentUser,
    userData && `${userData?.firstName} ${userData?.lastName}`
  );
  localStorage.setItem(
    "userInitial",
    userData &&
      `${userData?.firstName.charAt(0)}${userData?.lastName.charAt(0)}`
  );
};


export const getRoleMap = () => {
  const roleMap = localStorage.getItem(sessionInfo.RoleMap);
  if (roleMap) {
    return JSON.parse(roleMap);
  }
  return null;
};


export const getToken = () => {
  const token = localStorage.getItem(sessionInfo.AppToken);
  if (token && !isSessionExpired()) {
    return token;
  }
  return null;
};

export const getUserData = () => {
  const userData = localStorage.getItem(sessionInfo.AppUserData);
  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};



export const deleteToken = () => {
  localStorage.clear();
};

export const isSessionExpired = () => {
  const expire: any = localStorage.getItem(sessionInfo.AppTokenExpire);
  if (!expire) {
    return false;
  } else {
    return dayjs().isAfter(dayjs(JSON.parse(expire)));
  }
};

export const getFunctions = () => {
  const functions = localStorage.getItem(sessionInfo.FunctionData);
  if (functions) {
    return JSON.parse(functions);
  }
  return null;
};