import * as React from "react";

import { getRoleMap } from "../../utils/tokenConfig";
import Nav from "../Nav/Nav";
import {
  StyledNavGroup,
  StyledNavListings,
  StyledWrapper
} from "./NavBar.styles";

const NavBar = () => {
  const roleMap = getRoleMap();

  const generalModuleObject: { moduleGroup: string; modules: any[] } = {
    moduleGroup: "General",
    modules: []
  };

  const coreBankingModuleObject: { moduleGroup: string; modules: any[] } = {
    moduleGroup: "Core Banking",
    modules: []
  };


  const businessBankingModuleObject: { moduleGroup: string; modules: any[] } = {
    moduleGroup: "Business Banking",
    modules: []
  };

  roleMap.map((module: any) => {
    if (module.category === "User Management") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Biller Management") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Messaging Management") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Gift Card Management") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Approval Management") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Payroll Management") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "ID Verification") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Logs") {
      generalModuleObject.modules = [...generalModuleObject.modules, module];
    } else if (module.category === "Customer Management") {
      coreBankingModuleObject.modules = [
        ...coreBankingModuleObject.modules,
        module
      ];
    } else if (module.category === "Business Management") {
      coreBankingModuleObject.modules = [
        ...coreBankingModuleObject.modules,
        module
      ];
    } else if (module.category === "Posting Management") {
      coreBankingModuleObject.modules = [
        ...coreBankingModuleObject.modules,
        module
      ];
    } else if (module.category === "Accounting Management") {
      coreBankingModuleObject.modules = [
        ...coreBankingModuleObject.modules,
        module
      ];
    } else if (module.category === "Fees, Interest & Charges") {
      coreBankingModuleObject.modules = [
        ...coreBankingModuleObject.modules,
        module
      ];
    } else if (module.category === "Reporting Management") {
      coreBankingModuleObject.modules = [
        ...coreBankingModuleObject.modules,
        module
      ];
    } else if (module.category === "Merchant Verification") {
      businessBankingModuleObject.modules = [
        ...businessBankingModuleObject.modules,
        module
      ];
    }
  });

  const navModules = [generalModuleObject, coreBankingModuleObject, businessBankingModuleObject];

  console.log(navModules);

  return (
    <StyledWrapper>
      {navModules.map((item) => (
        <StyledNavGroup
          key={`parent-${item?.moduleGroup}`}
          data-group={`${item?.moduleGroup}`}
        >
          {item?.moduleGroup && <h4>{item.moduleGroup}</h4>}
          <StyledNavListings>
            {item?.modules?.map((module) => (
              <Nav module={module} key={module?.title} />
            ))}
          </StyledNavListings>
        </StyledNavGroup>
      ))}
    </StyledWrapper>
  );
};

export default NavBar;
