import React from "react";

import { Logo_White as Logo } from "../../assets/Svgpack";
import Spacer from "../../library/Spacer";
import NavBar from "../NavBar/NavBar";
import { LogoWrap, StyledSidebar } from "./Sidebar.styles";

type Props = {};

const SideBar = (props: Props) => {

  return (
    <StyledSidebar>
      <LogoWrap>
        <Logo />
      </LogoWrap>
      <Spacer size={40} />
      <div className="navbar-area">
        <NavBar />
      </div>
    </StyledSidebar>
  );
};

export default SideBar;
