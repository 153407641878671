import styled from "styled-components";

export const StyledWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;
export const StyledNavListings = styled.ul`
  padding: 0;
  list-style: none;
`;

export const StyledNavGroup = styled.div`
  h4 {
    font-weight: 400;
    margin-left: 16px;
    padding-block: 8px;
    font-size: calc(14 / 16 * 1rem);
    color: var(--color-white);
    text-transform: uppercase;
    letter-spacing: 1.88px;
  }
`;
