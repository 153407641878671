import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { createContext, useMemo, useReducer } from "react";
import { toast } from "react-toastify";

import { IUserValues } from "../modules/UserManagement/Users/user.types";
import {
  deleteToken,
  finishAuth,
  getToken,
  getUserData,
  isSessionExpired
} from "../utils/tokenConfig";

const initialState = {
  user: null,
  isAuth: false,
  isLoading: false
};

export interface AuthContextInterface {
  user: IUserValues | null;
  isAuth: boolean;
  login: (response: { [key: string]: any }) => void;
  isLoading: boolean;
  loadAuthUser: () => Promise<void> | null;
  logout: () => void;
  roleMap: [];
}

export const authcontextInitialState: AuthContextInterface = {
  user: null,
  isAuth: false,
  login: () => null,
  isLoading: false,
  loadAuthUser: () => null,
  logout: () => null,
  roleMap: []
};

export const AuthContext = createContext<AuthContextInterface | null>(
  authcontextInitialState
);

function authReducer(state: any, action: any) {
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        isLoading: true
      };
    case "LOGIN":
      return {
        ...state,
        user: action.user,
        roleMap: action.roleMap,
        isAuth: true,
        isLoading: false
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isAuth: false,
        isLoading: false
      };
    default:
      return state;
  }
}

export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  async function login(response: any) {
    dispatch({ type: "LOGIN_START" });

    const googleToken = response.tokenId;
    const authUrl = process.env.REACT_APP_AUTH_URL as string
    
    try {
      const res = await axios.post(authUrl, {
        token: googleToken
      });
      const token = res.data.data.token;
      const roleMap = res.data.data.roleMap;
      const decodedToken: {
        email: string;
        exp: number;
        firstName: string;
        iat: number;
        id: string;
        lastName: string;
        roles: [string];
        staffId: string;
      } = jwt_decode(token);
      const expiry = decodedToken?.exp;
      const user = {
        email: decodedToken.email,
        firstName: decodedToken.firstName,
        lastName: decodedToken.lastName,
        roles: decodedToken.roles,
        staffId: decodedToken.staffId
      };
      finishAuth(token, user, expiry, roleMap);
      dispatch({ type: "LOGIN", user, roleMap });
    } catch (error) {
      toast.warn(error as string);
    }
  }

  async function loadAuthUser() {
    try {
      const token = getToken();
      const user = getUserData();
      if (!isSessionExpired() && token && user) {
        dispatch({
          type: "LOGIN",
          user
        });
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  }

  function logout() {
    deleteToken();
    dispatch({ type: "LOGOUT" });
  }

  const value = useMemo(() => {
    return {
      isAuth: state.isAuth,
      isLoading: state.isLoading,
      login: login,
      logout: logout,
      loadAuthUser,
      user: state.user,
      roleMap: state.roleMap
    };
  }, [state]);

  return (
    <AuthContext.Provider
      value={value}
    >
      {children}
    </AuthContext.Provider>
  );
};
