import * as React from "react";

import Icon from "../../library/Icon";
import UnstyledButton from "../../library/UnstyledButton";
import { moduleIcons } from "../../routes/routeIconMap";
import { routeMap } from "../../routes/routeUrlMap";
import {
  StyledLabel,
  StyledMainLink,
  StyledNav,
  StyledNavItem,
  StyledSubLink,
} from "./Nav.styles";
import { NavProps } from "./Nav.types";

const Nav = ({ module }: NavProps) => {
  const [subNav, setSubNav] = React.useState(false);

  const showSubNav = () => setSubNav(!subNav);

  return (
    <StyledNav>
        <StyledNavItem
          className={subNav ? "menuVisible" : ""}
          onClick={showSubNav}
        >
          <StyledMainLink>
            <Icon
            icon={moduleIcons[module?.category ? module.category : ""]}
              size={24}
              variant="stroke"
              aria-hidden
            />
            <StyledLabel>{module.category}</StyledLabel>
          </StyledMainLink>
          <UnstyledButton className="icon-right">
            <Icon icon="chevronDown" size={16} variant="fill" />
          </UnstyledButton>
        </StyledNavItem>
      

      {subNav && (
        <div
          style={{
            paddingBlockEnd: "4px",
            borderBottom:
              "1px solid hsla(180, 9.090909090909067%, 97.84313725490196%, 0.09)",
          }}
        >
          {module?.modules?.map((item : any) => {
            const path: string = item.name;
            return (
              <StyledSubLink to={routeMap[path]} key={item.name}>
                <StyledLabel>{item.name}</StyledLabel>
              </StyledSubLink>
            );
          })}
        </div>
      )}
    </StyledNav>
  );
};

export default Nav;
