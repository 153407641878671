import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

const root = ReactDOM.createRoot(
  document.querySelector("#root") as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>

);


