import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { NotFoundIcon } from "../assets/Svgpack";

const ErrorPage = () => {
  return (
    <ContentWrap>
      <div>
        <div>
          <NotFoundIcon />
        </div>
        <h1> Page not found </h1>
        <p> The page you’re looking for doesn’t exist or has been moved. </p>
        <Link to={"/"}> Go Back Home </Link>
      </div>
    </ContentWrap>
  );
};

export default ErrorPage;

// style

export const ContentWrap = styled.article`
  max-width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 2.8rem;
    line-height: 1.3;
    letter-spacing: -1.21px;
    margin: 30px 0 20px 0;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.3;
    margin: 0 0 30px 0;
  }

  a {
    transition: all 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    min-width: 200px;
    aspect-ratio: 1;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
    border-radius: 8px;
    padding-inline: 10px;
    outline: none;
    letter-spacing: -0.12px;
    aspect-ratio: 1;
    cursor: pointer;
    text-decoration: none;
    background-color: hsla(122, 90%, 29%, 1);
    color: hsl(0, 0%, 100%);
    border: none;
  }
`;
