import React from "react";

export const icons = {
  passwordHide: (
    <>
      <path d="m21.76 12.72-1.42-1.63c0.38-0.5 0.6-0.86 0.63-0.92 0.28-0.46997 0.12-1.09-0.35-1.37s-1.09-0.13-1.37 0.35c-0.03 0.05-2.89 4.75-7.25 4.75-4.36 0-7.01-4.5-7.19-4.81-0.14-0.31-0.42999-0.54-0.79999-0.59-0.56-0.06-1.04 0.33-1.11 0.88-0.05 0.4 0.35999 1.07 0.80999 1.68l-1.45 1.66c-0.36 0.42-0.32002 1.05 0.09998 1.41 0.19 0.17 0.42 0.25 0.66 0.25 0.28 0 0.56-0.12 0.75-0.34l1.26-1.44c0.52 0.53 1.12 1.07 1.81 1.56l-1.24 2.27c-0.26 0.48-0.09001 1.09 0.39999 1.36 0.15 0.08 0.32001 0.12 0.48001 0.12 0.35 0 0.69998-0.19 0.87998-0.52l1.22-2.23c0.74 0.33 1.56 0.56 2.44 0.67v2.05c0 0.55 0.45 1 1 1s1-0.45 1-1v-2.04c0.88-0.11 1.69-0.36 2.43-0.69l1.23 2.25c0.18 0.33 0.52 0.52 0.88 0.52 0.16 0 0.33-0.04 0.48-0.12 0.48-0.26 0.66-0.87 0.4-1.36l-1.24-2.27c0.71-0.49 1.32-1.03 1.84-1.56l1.23 1.41c0.2 0.23 0.47 0.34 0.75 0.34 0.23 0 0.47-0.08 0.66-0.25 0.42-0.36 0.46-0.99 0.1-1.41l-0.02 0.02z" />
    </>
  ),
  chevronDown: (
    <>
      <path d="M20.02 8.5L12.02 16.5L4.02002 8.5" strokeWidth="2.5" />
    </>
  ),
  passwordShow: (
    <>
      <path d="m11.96 20.2c-4.41 0-7.6-3.23-9.11-5.15-1.19-1.52-1.15-3.61 0.09999-5.09 1.98-2.35 5.17-5.15 9.06-5.15 3.89 0 7.08 2.81 9.05 5.18 1.23 1.48 1.25 3.64 0.04 5.13-1.88 2.32-4.99 5.09-9.13 5.09l-0.01-0.01zm0.05-13.39c-3.12 0-5.82 2.42-7.53 4.44-0.63 0.75-0.65 1.8-0.05 2.57 1.29 1.64 3.98 4.39 7.54 4.39 3.36 0 5.98-2.36 7.58-4.35 0.62-0.76 0.61-1.83-0.03-2.59-1.39-1.66-4.2-4.46-7.51-4.46z" />

      <path d="m12 16.5c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-6c-1.1 0-2 0.9-2 2s0.9 2 2 2 2-0.9 2-2-0.9-2-2-2z" />
    </>
  ),
  payments: (
    <>
      <path d="M16.1401 2.96004L7.11012 5.96004C1.04012 7.99004 1.04012 11.3 7.11012 13.32L9.79012 14.21L10.6801 16.89C12.7001 22.96 16.0201 22.96 18.0401 16.89L21.0501 7.87004C22.3901 3.82004 20.1901 1.61004 16.1401 2.96004ZM16.4601 8.34004L12.6601 12.16C12.5101 12.31 12.3201 12.38 12.1301 12.38C11.9401 12.38 11.7501 12.31 11.6001 12.16C11.3101 11.87 11.3101 11.39 11.6001 11.1L15.4001 7.28004C15.6901 6.99004 16.1701 6.99004 16.4601 7.28004C16.7501 7.57004 16.7501 8.05004 16.4601 8.34004Z" />
    </>
  ),
  home: (
    <>
      <path d="M9.02 2.84016L3.63 7.04016C2.73 7.74016 2 9.23016 2 10.3602V17.7702C2 20.0902 3.89 21.9902 6.21 21.9902H17.79C20.11 21.9902 22 20.0902 22 17.7802V10.5002C22 9.29016 21.19 7.74016 20.2 7.05016L14.02 2.72016C12.62 1.74016 10.37 1.79016 9.02 2.84016Z" />
      <path opacity="0.34" d="M12 17.9902V14.9902" strokeWidth="1.5" />
    </>
  ),
  cards: (
    <>
      <path d="M2 12.6099H19" strokeWidth="1.5" />
      <path d="M19 10.2798V17.4298C18.97 20.2798 18.19 20.9998 15.22 20.9998H5.78003C2.76003 20.9998 2 20.2498 2 17.2698V10.2798C2 7.5798 2.63 6.70981 5 6.56981C5.24 6.55981 5.50003 6.5498 5.78003 6.5498H15.22C18.24 6.5498 19 7.2998 19 10.2798Z" />
      <path
        opacity="0.4"
        d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
      />
      <path opacity="0.4" d="M5.25 17.8101H6.96997" strokeWidth="1.5" />
      <path opacity="0.4" d="M9.10986 17.8101H12.5499" strokeWidth="1.5" />
    </>
  ),
  more: (
    <>
      <path d="M3 9.10986V14.8799C3 16.9999 3 16.9999 5 18.3499L10.5 21.5299C11.33 22.0099 12.68 22.0099 13.5 21.5299L19 18.3499C21 16.9999 21 16.9999 21 14.8899V9.10986C21 6.99986 21 6.99986 19 5.64986L13.5 2.46986C12.68 1.98986 11.33 1.98986 10.5 2.46986L5 5.64986C3 6.99986 3 6.99986 3 9.10986Z" />
      <path
        opacity="0.34"
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      />
    </>
  ),
  chevronRight: (
    <>
      <path d="M5.93994 13.2802L10.2866 8.93355C10.7999 8.42021 10.7999 7.58021 10.2866 7.06688L5.93994 2.72021" />
    </>
  ),
  chevronLeft: (
    <>
      <g clipPath="url(#clip0_1576_27520)">
        <path
          d="M7.828 11H20V13H7.828L13.192 18.364L11.778 19.778L4 12L11.778 4.222L13.192 5.636L7.828 11Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1576_27520">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  airtime: (
    <>
      <path d="M20 7.04V16.96C20 18.48 19.86 19.56 19.5 20.33C19.5 20.34 19.49 20.36 19.48 20.37C19.26 20.65 18.97 20.79 18.63 20.79C18.1 20.79 17.46 20.44 16.77 19.7C15.95 18.82 14.69 18.89 13.97 19.85L12.96 21.19C12.56 21.73 12.03 22 11.5 22C10.97 22 10.44 21.73 10.04 21.19L9.02002 19.84C8.31002 18.89 7.05999 18.82 6.23999 19.69L6.22998 19.7C5.09998 20.91 4.10002 21.09 3.52002 20.37C3.51002 20.36 3.5 20.34 3.5 20.33C3.14 19.56 3 18.48 3 16.96V7.04C3 5.52 3.14 4.44 3.5 3.67C3.5 3.66 3.50002 3.65 3.52002 3.64C4.09002 2.91 5.09998 3.09 6.22998 4.3L6.23999 4.31C7.05999 5.18 8.31002 5.11 9.02002 4.16L10.04 2.81C10.44 2.27 10.97 2 11.5 2C12.03 2 12.56 2.27 12.96 2.81L13.97 4.15C14.69 5.11 15.95 5.18 16.77 4.3C17.46 3.56 18.1 3.21 18.63 3.21C18.97 3.21 19.26 3.36 19.48 3.64C19.5 3.65 19.5 3.66 19.5 3.67C19.86 4.44 20 5.52 20 7.04Z" />
      <path opacity="0.4" d="M8 10.25H16" strokeWidth="1.5" />
      <path opacity="0.4" d="M8 13.75H14" strokeWidth="1.5" />
    </>
  ),
  bill: (
    <>
      <path d="M22 6V8.42C22 10 21 11 19.42 11H16V4.01C16 2.9 16.91 2 18.02 2C19.11 2.01 20.11 2.45 20.83 3.17C21.55 3.9 22 4.9 22 6Z" />
      <path d="M2 7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z" />
      <path opacity="0.4" d="M9 13.0098H12" strokeWidth="1.5" />
      <path opacity="0.4" d="M9 9.00977H12" strokeWidth="1.5" />
      <path opacity="0.4" d="M5.99561 13H6.00459" strokeWidth="2" />
      <path opacity="0.4" d="M5.99548 9H6.00447" strokeWidth="2" />
    </>
  ),
  gifts: (
    <>
      <path opacity="0.4" d="M14.2617 15.998H9.26172" strokeWidth="1.5" />
      <path d="M12.66 2.51814L12.63 2.58814L9.72996 9.31814H6.87996C6.19996 9.31814 5.54996 9.45814 4.95996 9.70814L6.70996 5.52814L6.74996 5.42814L6.81996 5.26814C6.83996 5.20814 6.85996 5.14814 6.88996 5.09814C8.19996 2.06814 9.67996 1.37814 12.66 2.51814Z" />
      <path d="M18.05 9.51807C17.6 9.37807 17.12 9.31807 16.64 9.31807H9.72998L12.63 2.58807L12.66 2.51807C12.81 2.56807 12.95 2.63807 13.1 2.69807L15.31 3.62807C16.54 4.13807 17.4 4.66807 17.92 5.30807C18.02 5.42807 18.1 5.53807 18.17 5.66807C18.26 5.80807 18.33 5.94807 18.37 6.09807C18.41 6.18807 18.44 6.27807 18.46 6.35807C18.73 7.19807 18.57 8.22807 18.05 9.51807Z" />
      <path d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z" />
      <path
        opacity="0.4"
        d="M6.71 5.52832L4.96 9.70832C3.22 10.4583 2 12.1883 2 14.1983V11.2683C2 8.42832 4.02 6.05832 6.71 5.52832Z"
      />
      <path
        opacity="0.4"
        d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z"
      />
    </>
  ),
  link: (
    <>
      <path d="M16.8926 13.805L17.6777 13.0199C19.2479 11.4497 19.2531 8.87603 17.6777 7.30063C16.1075 5.73044 13.5339 5.72524 11.9584 7.30063L11.1734 8.08574" />
      <path d="M8.05882 11.2003L7.27892 11.9801C5.70353 13.5556 5.70353 16.124 7.27892 17.6994C8.84911 19.2696 11.4228 19.2748 12.9982 17.6994L13.7781 16.9196" />
      <path opacity="0.4" d="M10.3986 14.5799L14.558 10.4204" />
    </>
  ),
  globe: (
    <>
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
      <g opacity="0.4">
        <path d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961" />
        <path d="M15 3C16.95 8.84 16.95 15.16 15 21" strokeWidth="1.5" />
        <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" strokeWidth="1.5" />
        <path d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001" />
      </g>
    </>
  ),
  person: (
    <>
      <path d="M8.00008 8.00016C9.84103 8.00016 11.3334 6.50778 11.3334 4.66683C11.3334 2.82588 9.84103 1.3335 8.00008 1.3335C6.15913 1.3335 4.66675 2.82588 4.66675 4.66683C4.66675 6.50778 6.15913 8.00016 8.00008 8.00016Z" />
      <path d="M12.8068 10.4933L10.4468 12.8534C10.3534 12.9467 10.2668 13.12 10.2468 13.2467L10.1201 14.1467C10.0734 14.4733 10.3001 14.7 10.6268 14.6533L11.5268 14.5267C11.6534 14.5067 11.8334 14.42 11.9201 14.3267L14.2801 11.9667C14.6868 11.56 14.8801 11.0867 14.2801 10.4867C13.6868 9.89334 13.2135 10.0867 12.8068 10.4933Z" />
      <path d="M12.4668 10.8335C12.6668 11.5535 13.2268 12.1135 13.9468 12.3135" />
      <path
        opacity="0.4"
        d="M2.27344 14.6667C2.27344 12.0867 4.84012 10 8.00012 10C8.69346 10 9.3601 10.1 9.9801 10.2867"
      />
    </>
  ),
  arrowLeft: (
    <>
      <path d="M7.828 11.0002H20V13.0002H7.828L13.192 18.3642L11.778 19.7782L4 12.0002L11.778 4.22217L13.192 5.63617L7.828 11.0002Z" />
    </>
  ),
  close: (
    <>
      <path d="M11.9997 11.0862L16.9497 6.13623L18.3637 7.55023L13.4137 12.5002L18.3637 17.4502L16.9497 18.8642L11.9997 13.9142L7.04974 18.8642L5.63574 17.4502L10.5857 12.5002L5.63574 7.55023L7.04974 6.13623L11.9997 11.0862Z" />
    </>
  ),
  warning: (
    <>
      <path
        d="M43.702 15.9491C43.978 15.1989 45.039 15.1989 45.315 15.9491C45.4015 16.1845 45.5869 16.3704 45.822 16.4579C46.5723 16.7372 46.5723 17.7999 45.822 18.0792C45.5869 18.1667 45.4015 18.3526 45.315 18.588C45.039 19.3382 43.978 19.3382 43.702 18.588C43.6155 18.3526 43.4301 18.1667 43.195 18.0792C42.4447 17.7999 42.4447 16.7372 43.195 16.4579C43.4301 16.3704 43.6155 16.1845 43.702 15.9491Z"
        fill="#FFE5B5"
      />
      <path
        d="M30.9022 2.19275C31.1781 1.44252 32.2392 1.44252 32.5152 2.19275C32.6017 2.42813 32.7871 2.61405 33.0222 2.70155C33.7725 2.98088 33.7725 4.04351 33.0222 4.32284C32.7871 4.41034 32.6017 4.59626 32.5152 4.83163C32.2392 5.58187 31.1781 5.58187 30.9022 4.83163C30.8156 4.59626 30.6303 4.41034 30.3952 4.32284C29.6449 4.04351 29.6449 2.98088 30.3952 2.70155C30.6303 2.61405 30.8156 2.42813 30.9022 2.19275Z"
        fill="#FFE5B5"
      />
      <path
        d="M19.8091 5.26469C21.0514 4.54312 22.5851 4.54312 23.8274 5.26469L38.7225 13.9168C39.955 14.6327 40.7134 15.9503 40.7134 17.3756V34.7221C40.7134 36.1474 39.955 37.465 38.7225 38.1809L23.8274 46.8331C22.5851 47.5546 21.0514 47.5546 19.8091 46.8331L4.91397 38.1809C3.68151 37.465 2.92309 36.1474 2.92309 34.7221V17.3756C2.92309 15.9503 3.68151 14.6327 4.91397 13.9168L19.8091 5.26469Z"
        fill="white"
      />
      <path
        d="M19.8097 9.65483C21.052 8.93325 22.5858 8.93325 23.828 9.65483L34.9441 16.1118C36.1766 16.8277 36.935 18.1454 36.935 19.5707V32.5269C36.935 33.9522 36.1766 35.2698 34.9441 35.9857L23.828 42.4427C22.5858 43.1643 21.052 43.1643 19.8097 42.4427L8.69361 35.9857C7.46115 35.2698 6.70273 33.9522 6.70273 32.5269V19.5707C6.70273 18.1454 7.46115 16.8277 8.69361 16.1118L19.8097 9.65483Z"
        fill="#FFF7E6"
      />
      <path
        opacity="0.4"
        d="M23.3638 25.5713V22.9999C23.3638 22.2899 22.7533 21.7142 22.0002 21.7142C21.2471 21.7142 20.6365 22.2899 20.6365 22.9999V25.5713C20.6365 26.2813 21.2471 26.857 22.0002 26.857C22.7533 26.857 23.3638 26.2813 23.3638 25.5713ZM22.0002 20C20.2429 20 18.8184 21.3431 18.8184 22.9999V25.5713C18.8184 27.2281 20.2429 28.5712 22.0002 28.5712C23.7574 28.5712 25.182 27.2281 25.182 25.5713V22.9999C25.182 21.3431 23.7574 20 22.0002 20Z"
        fill="#FFAA08"
      />
      <rect
        x="17"
        y="24.2861"
        width="10"
        height="7.71408"
        rx="1"
        fill="#FFAA08"
      />
    </>
  ),
  beneficiary: (
    <>
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="#1A1C1F"
      />
      <path
        opacity="0.4"
        d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37"
        stroke="#1A1C1F"
      />
      <path
        d="M22 18C22 18.75 21.79 19.46 21.42 20.06C21.21 20.42 20.94 20.74 20.63 21C19.93 21.63 19.01 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.74 14.58 15.61 15.5 14.88C16.19 14.33 17.06 14 18 14C20.21 14 22 15.79 22 18Z"
        stroke="#1A1C1F"
      />
      <path d="M16.4404 18L17.4304 18.99L19.5604 17.02" stroke="#1A1C1F" />
    </>
  ),
  paymentLinkSummary: (
    <>
      <path
        d="M90.7202 31.9168C91.0913 30.9139 92.5099 30.9139 92.881 31.9168L93.5614 33.7556C93.6781 34.071 93.9267 34.3196 94.242 34.4362L96.0808 35.1167C97.0838 35.4878 97.0838 36.9063 96.0808 37.2775L94.242 37.9579C93.9267 38.0746 93.6781 38.3232 93.5614 38.6385L92.881 40.4773C92.5099 41.4803 91.0913 41.4803 90.7202 40.4773L90.0398 38.6385C89.9231 38.3232 89.6745 38.0746 89.3591 37.9579L87.5203 37.2775C86.5174 36.9063 86.5174 35.4878 87.5203 35.1167L89.3591 34.4362C89.6745 34.3196 89.9231 34.071 90.0398 33.7556L90.7202 31.9168Z"
        fill="#DBEFFF"
        stroke="#1A1C1F"
      />
      <path
        d="M64.3198 3.71662C64.6909 2.71367 66.1095 2.71367 66.4806 3.71663L67.161 5.55543C67.2777 5.87076 67.5263 6.11937 67.8416 6.23605L69.6804 6.91647C70.6834 7.2876 70.6834 8.70615 69.6804 9.07728L67.8416 9.7577C67.5263 9.87438 67.2777 10.123 67.161 10.4383L66.4806 12.2771C66.1095 13.2801 64.6909 13.2801 64.3198 12.2771L63.6394 10.4383C63.5227 10.123 63.2741 9.87438 62.9588 9.7577L61.1199 9.07728C60.117 8.70615 60.117 7.2876 61.1199 6.91647L62.9588 6.23605C63.2741 6.11937 63.5227 5.87076 63.6394 5.55543L64.3198 3.71662Z"
        fill="#DBEFFF"
        stroke="#1A1C1F"
      />
      <path
        d="M34.5 15.2604C40.9974 11.5091 49.0026 11.5091 55.5 15.2604L73.4711 25.6361C79.9686 29.3874 83.9711 36.32 83.9711 43.8226V64.5739C83.9711 72.0765 79.9686 79.0091 73.4711 82.7604L55.5 93.1361C49.0026 96.8874 40.9974 96.8874 34.5 93.1361L16.5289 82.7604C10.0314 79.0091 6.02886 72.0765 6.02886 64.5739V43.8226C6.02886 36.32 10.0314 29.3874 16.5289 25.6361L34.5 15.2604Z"
        stroke="#1A1C1F"
      />
      <path
        d="M34.5 24.2604C40.9974 20.5091 49.0026 20.5091 55.5 24.2604L65.6769 30.1361C72.1743 33.8874 76.1769 40.82 76.1769 48.3226V60.0739C76.1769 67.5765 72.1743 74.5091 65.6769 78.2604L55.5 84.1361C49.0026 87.8874 40.9974 87.8874 34.5 84.1361L24.3231 78.2604C17.8257 74.5091 13.8231 67.5765 13.8231 60.0739V48.3226C13.8231 40.82 17.8257 33.8874 24.3231 30.1361L34.5 24.2604Z"
        fill="#008CFF"
        fillOpacity="0.14"
      />
    </>
  ),
  warn: (
    <>
      <path d="M19.5099 5.85L13.5699 2.42C12.5999 1.86 11.3999 1.86 10.4199 2.42L4.48992 5.85C3.51992 6.41 2.91992 7.45 2.91992 8.58V15.42C2.91992 16.54 3.51992 17.58 4.48992 18.15L10.4299 21.58C11.3999 22.14 12.5999 22.14 13.5799 21.58L19.5199 18.15C20.4899 17.59 21.0899 16.55 21.0899 15.42V8.58C21.0799 7.45 20.4799 6.42 19.5099 5.85ZM11.2499 7.75C11.2499 7.34 11.5899 7 11.9999 7C12.4099 7 12.7499 7.34 12.7499 7.75V13C12.7499 13.41 12.4099 13.75 11.9999 13.75C11.5899 13.75 11.2499 13.41 11.2499 13V7.75ZM12.9199 16.63C12.8699 16.75 12.7999 16.86 12.7099 16.96C12.5199 17.15 12.2699 17.25 11.9999 17.25C11.8699 17.25 11.7399 17.22 11.6199 17.17C11.4899 17.12 11.3899 17.05 11.2899 16.96C11.1999 16.86 11.1299 16.75 11.0699 16.63C11.0199 16.51 10.9999 16.38 10.9999 16.25C10.9999 15.99 11.0999 15.73 11.2899 15.54C11.3899 15.45 11.4899 15.38 11.6199 15.33C11.9899 15.17 12.4299 15.26 12.7099 15.54C12.7999 15.64 12.8699 15.74 12.9199 15.87C12.9699 15.99 12.9999 16.12 12.9999 16.25C12.9999 16.38 12.9699 16.51 12.9199 16.63Z" />
    </>
  ),
  chevronUp: (
    <>
      <path d="M18.6796 13.9802L15.4696 10.7702L13.5096 8.80023C12.6796 7.97023 11.3296 7.97023 10.4996 8.80023L5.31958 13.9802C4.63958 14.6602 5.12958 15.8202 6.07958 15.8202H11.6896H17.9196C18.8796 15.8202 19.3596 14.6602 18.6796 13.9802Z" />
    </>
  ),
  messaging: (
    <>
      <path d="M15.3334 17.6667H8.66671C6.66671 17.6667 5.33337 16.6667 5.33337 14.3333V9.66666C5.33337 7.33333 6.66671 6.33333 8.66671 6.33333H15.3334C17.3334 6.33333 18.6667 7.33333 18.6667 9.66666V14.3333C18.6667 16.6667 17.3334 17.6667 15.3334 17.6667Z" />
      <path
        opacity="0.4"
        d="M15.3333 10L13.2466 11.6667C12.56 12.2133 11.4333 12.2133 10.7466 11.6667L8.66663 10"
      />
    </>
  ),
  biller: (
    <>
      <path d="M18.6666 7.99999V9.61333C18.6666 10.6667 18 11.3333 16.9466 11.3333H14.6666V6.67333C14.6666 5.93333 15.2733 5.33333 16.0133 5.33333C16.74 5.33999 17.4066 5.63333 17.8866 6.11333C18.3666 6.59999 18.6666 7.26666 18.6666 7.99999Z" />
      <path d="M5.33337 8.66666V18C5.33337 18.5533 5.96004 18.8667 6.40004 18.5333L7.54004 17.68C7.80671 17.48 8.18004 17.5067 8.42004 17.7467L9.52671 18.86C9.78671 19.12 10.2134 19.12 10.4734 18.86L11.5934 17.74C11.8267 17.5067 12.2 17.48 12.46 17.68L13.6 18.5333C14.04 18.86 14.6667 18.5467 14.6667 18V6.66666C14.6667 5.93333 15.2667 5.33333 16 5.33333H8.66671H8.00004C6.00004 5.33333 5.33337 6.52666 5.33337 7.99999V8.66666Z" />
      <path opacity="0.4" d="M8.16663 10.6667H11.8333" strokeWidth="1.5" />
    </>
  ),
  userManagement: (
    <>
      <path d="M12 12C13.8409 12 15.3333 10.5076 15.3333 8.66666C15.3333 6.82571 13.8409 5.33333 12 5.33333C10.159 5.33333 8.66663 6.82571 8.66663 8.66666C8.66663 10.5076 10.159 12 12 12Z" />
      <path d="M16.8067 14.4933L14.4466 16.8534C14.3533 16.9467 14.2666 17.12 14.2466 17.2467L14.12 18.1467C14.0733 18.4733 14.3 18.7 14.6267 18.6533L15.5266 18.5267C15.6533 18.5067 15.8333 18.42 15.92 18.3267L18.28 15.9667C18.6866 15.56 18.88 15.0867 18.28 14.4867C17.6866 13.8933 17.2133 14.0867 16.8067 14.4933Z" />
      <path d="M16.4668 14.8333C16.6668 15.5533 17.2268 16.1133 17.9468 16.3133" />
      <path
        opacity="0.4"
        d="M6.27332 18.6667C6.27332 16.0867 8.84 14 12 14C12.6933 14 13.36 14.1 13.98 14.2867"
      />
    </>
  ),
  customerInfo: (
    <>
      <path
        opacity="0.4"
        d="M12.0799 12.52C12.0332 12.5133 11.9732 12.5133 11.9199 12.52C10.7466 12.48 9.81323 11.52 9.81323 10.34C9.81323 9.13334 10.7866 8.15334 11.9999 8.15334C13.2066 8.15334 14.1866 9.13334 14.1866 10.34C14.1799 11.52 13.2532 12.48 12.0799 12.52Z"
      />
      <path
        opacity="0.34"
        d="M16.4933 16.92C15.3066 18.0067 13.7333 18.6667 11.9999 18.6667C10.2666 18.6667 8.69326 18.0067 7.50659 16.92C7.57326 16.2933 7.97326 15.68 8.68659 15.2C10.5133 13.9867 13.4999 13.9867 15.3133 15.2C16.0266 15.68 16.4266 16.2933 16.4933 16.92Z"
      />
      <path d="M11.9999 18.6667C15.6818 18.6667 18.6666 15.6819 18.6666 12C18.6666 8.3181 15.6818 5.33333 11.9999 5.33333C8.31802 5.33333 5.33325 8.3181 5.33325 12C5.33325 15.6819 8.31802 18.6667 11.9999 18.6667Z" />
    </>
  ),
  customerAcc: (
    <>
      <path d="M9.99992 18.6667H13.9999C17.3333 18.6667 18.6666 17.3333 18.6666 14V10C18.6666 6.66668 17.3333 5.33334 13.9999 5.33334H9.99992C6.66659 5.33334 5.33325 6.66668 5.33325 10V14C5.33325 17.3333 6.66659 18.6667 9.99992 18.6667Z" />
      <g opacity="0.4">
        <path d="M9.33325 16.6667H14.6666C15.9999 16.6667 16.6666 16 16.6666 14.6667V9.33334C16.6666 8.00001 15.9999 7.33334 14.6666 7.33334H9.33325C7.99992 7.33334 7.33325 8.00001 7.33325 9.33334V14.6667C7.33325 16 7.99992 16.6667 9.33325 16.6667Z" />
        <path d="M7.33325 10.3333H8.98658C9.90658 10.3333 10.6532 11.08 10.6532 12C10.6532 12.92 9.90658 13.6667 8.98658 13.6667H7.33325" />
        <path d="M16.6665 10.66H14.6665" />
        <path d="M16.6665 13.3333H14.6665" />
      </g>
      <path opacity="0.4" d="M8.80005 12H8.86672" strokeWidth="2" />
    </>
  ),
  approval: (
    <>
      <path d="M6 13L9.33333 16.3333L18.3333 7.33334" />
      <path opacity="0.4" d="M15.3333 7.3333L9.66663 13L8.66663 12" />
    </>
  ),
  giftCards: (
    <>
      <path d="M11.2682 17.2467H15.5815C18.0482 17.2467 18.6615 16.6334 18.6615 14.1667C17.8082 14.1667 17.1215 13.4734 17.1215 12.6267C17.1215 11.7734 17.8082 11.0801 18.6615 11.0801V10.4667C18.6615 8.00005 18.0482 7.38672 15.5815 7.38672H11.3282V11.9134" />
      <path
        opacity="0.4"
        d="M11.3291 15.2467V17.2467H9.48242C8.49575 17.2467 7.91575 16.5733 7.27575 15.0267L7.15575 14.7267C7.96242 14.4067 8.35575 13.4733 8.01575 12.6667C7.68908 11.86 6.76242 11.4733 5.94908 11.8067L5.83575 11.52C4.87575 9.17333 5.20908 8.35333 7.55575 7.38667L9.31575 6.66667L11.3291 11.5467V13.2467"
      />
      <path d="M9.44146 17.2467H9.32812" />
    </>
  ),
  accountOfficer: (
    <>
      <path d="M9.33338 18.6667H14.6667C17.3467 18.6667 17.8267 17.5933 17.9667 16.2867L18.4667 10.9533C18.6467 9.32667 18.18 8 15.3334 8H8.66671C5.82005 8 5.35338 9.32667 5.53338 10.9533L6.03338 16.2867C6.17338 17.5933 6.65338 18.6667 9.33338 18.6667Z" />
      <path
        opacity="0.4"
        d="M9.33325 8.00001V7.46668C9.33325 6.28668 9.33325 5.33334 11.4666 5.33334H12.5333C14.6666 5.33334 14.6666 6.28668 14.6666 7.46668V8.00001"
      />
      <g opacity="0.4">
        <path d="M13.3334 12.6667V13.3333C13.3334 13.34 13.3334 13.34 13.3334 13.3467C13.3334 14.0733 13.3267 14.6667 12.0001 14.6667C10.6801 14.6667 10.6667 14.08 10.6667 13.3533V12.6667C10.6667 12 10.6667 12 11.3334 12H12.6667C13.3334 12 13.3334 12 13.3334 12.6667Z" />
        <path d="M18.4333 11.3333C16.8933 12.4533 15.1333 13.12 13.3333 13.3467" />
        <path d="M5.74609 11.513C7.24609 12.5397 8.93943 13.1597 10.6661 13.353" />
      </g>
    </>
  ),
  feeInterest: (
    <>
      <path d="M15.3333 17.6667H8.66659C6.66659 17.6667 5.33325 16.6667 5.33325 14.3333V9.66668C5.33325 7.33334 6.66659 6.33334 8.66659 6.33334H15.3333C17.3333 6.33334 18.6666 7.33334 18.6666 9.66668V14.3333C18.6666 16.6667 17.3333 17.6667 15.3333 17.6667Z" />
      <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" />
      <path opacity="0.4" d="M7.66675 10.3333V13.6667" />
      <path opacity="0.4" d="M16.3333 10.3333V13.6667" />
    </>
  ),
  report: (
    <>
      <path d="M17.3334 9.50001V16C17.3334 18 16.1401 18.6667 14.6667 18.6667H9.33341C7.86008 18.6667 6.66675 18 6.66675 16V9.50001C6.66675 7.33334 7.86008 6.83334 9.33341 6.83334C9.33341 7.24668 9.50006 7.62001 9.7734 7.89334C10.0467 8.16667 10.4201 8.33334 10.8334 8.33334H13.1667C13.9934 8.33334 14.6667 7.66001 14.6667 6.83334C16.1401 6.83334 17.3334 7.33334 17.3334 9.50001Z" />
      <path d="M14.6666 6.83334C14.6666 7.66001 13.9933 8.33334 13.1666 8.33334H10.8333C10.4199 8.33334 10.0466 8.16667 9.77323 7.89334C9.4999 7.62001 9.33325 7.24668 9.33325 6.83334C9.33325 6.00668 10.0066 5.33334 10.8333 5.33334H13.1666C13.5799 5.33334 13.9533 5.50001 14.2266 5.77335C14.4999 6.04668 14.6666 6.42001 14.6666 6.83334Z" />
      <path opacity="0.4" d="M9.33325 12.6667H11.9999" />
      <path opacity="0.4" d="M9.33325 15.3333H14.6666" />
    </>
  ),
  logOut: (
    <>
      <path d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z" />
      <path d="M4.55994 11.25L6.62994 9.18C6.77994 9.03 6.84994 8.84 6.84994 8.65C6.84994 8.46 6.77994 8.26 6.62994 8.12C6.33994 7.83 5.85994 7.83 5.56994 8.12L2.21994 11.47C1.92994 11.76 1.92994 12.24 2.21994 12.53L5.56994 15.88C5.85994 16.17 6.33994 16.17 6.62994 15.88C6.91994 15.59 6.91994 15.11 6.62994 14.82L4.55994 12.75H8.99994V11.25H4.55994Z" />
    </>
  ),
  moreOptions: (
    <>
      <g clipPath="url(#clip0_2097_17199)">
        <path
          d="M12.7869 3.30432C11.9619 3.30432 11.2869 3.97932 11.2869 4.80432C11.2869 5.62932 11.9619 6.30432 12.7869 6.30432C13.6119 6.30432 14.2869 5.62932 14.2869 4.80432C14.2869 3.97932 13.6119 3.30432 12.7869 3.30432ZM12.7869 18.3043C11.9619 18.3043 11.2869 18.9793 11.2869 19.8043C11.2869 20.6293 11.9619 21.3043 12.7869 21.3043C13.6119 21.3043 14.2869 20.6293 14.2869 19.8043C14.2869 18.9793 13.6119 18.3043 12.7869 18.3043ZM12.7869 10.8043C11.9619 10.8043 11.2869 11.4793 11.2869 12.3043C11.2869 13.1293 11.9619 13.8043 12.7869 13.8043C13.6119 13.8043 14.2869 13.1293 14.2869 12.3043C14.2869 11.4793 13.6119 10.8043 12.7869 10.8043Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2097_17199">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.786865 0.304321)"
          />
        </clipPath>
      </defs>
    </>
  ),
  business: (
    <>
      <path d="M15.3333 17.6666H8.66659C6.66659 17.6666 5.33325 16.6666 5.33325 14.3333V9.66665C5.33325 7.33331 6.66659 6.33331 8.66659 6.33331H15.3333C17.3333 6.33331 18.6666 7.33331 18.6666 9.66665V14.3333C18.6666 16.6666 17.3333 17.6666 15.3333 17.6666Z" />
      <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" />
      <path opacity="0.4" d="M7.66675 10.3333V13.6666" />
      <path opacity="0.4" d="M16.3333 10.3333V13.6666" />
    </>
  ),
  search: (
    <>
      <path
        d="M2.24609 8.71094C2.24609 9.82422 2.45442 10.8691 2.87109 11.8457C3.28776 12.8222 3.86718 13.6816 4.60937 14.4239C5.35156 15.166 6.20768 15.7455 7.17773 16.1621C8.15429 16.5787 9.19922 16.7871 10.3125 16.7871C11.1003 16.7871 11.8522 16.6764 12.5684 16.4551C13.291 16.2272 13.9584 15.918 14.5702 15.5274L18.8184 19.7754C18.9811 19.9381 19.1666 20.0586 19.375 20.1367C19.5834 20.2149 19.7981 20.2539 20.0195 20.2539C20.345 20.2539 20.6315 20.179 20.8789 20.0292C21.1262 19.8861 21.3216 19.6875 21.4649 19.4336C21.6081 19.1797 21.6797 18.8965 21.6797 18.584C21.6797 18.3561 21.6374 18.1412 21.5527 17.9395C21.4746 17.7441 21.3574 17.5651 21.2011 17.4024L16.9922 13.1739C17.4284 12.5424 17.7669 11.849 18.0077 11.0938C18.2552 10.3385 18.3789 9.54427 18.3789 8.71094C18.3789 7.60416 18.1706 6.5625 17.7539 5.58594C17.3372 4.60937 16.7577 3.75325 16.0156 3.01757C15.2735 2.27539 14.414 1.69596 13.4375 1.2793C12.4674 0.862629 11.4258 0.654297 10.3125 0.654297C9.19922 0.654297 8.15429 0.862629 7.17773 1.2793C6.20768 1.69596 5.35156 2.27539 4.60937 3.01757C3.86718 3.75325 3.28776 4.60937 2.87109 5.58594C2.45442 6.5625 2.24609 7.60416 2.24609 8.71094ZM4.61913 8.71094C4.61913 7.92969 4.76562 7.19726 5.05859 6.51367C5.35807 5.82356 5.76822 5.2181 6.28906 4.69726C6.80989 4.16992 7.41211 3.75976 8.09569 3.4668C8.78581 3.17382 9.52473 3.02735 10.3125 3.02735C11.1003 3.02735 11.8359 3.17382 12.5195 3.4668C13.2096 3.75976 13.8151 4.16992 14.336 4.69726C14.8567 5.2181 15.2636 5.82356 15.5566 6.51367C15.8561 7.19726 16.0059 7.92969 16.0059 8.71094C16.0059 9.4987 15.8561 10.2376 15.5566 10.9277C15.2636 11.6113 14.8567 12.2135 14.336 12.7344C13.8151 13.2553 13.2096 13.6654 12.5195 13.9649C11.8359 14.2577 11.1003 14.4042 10.3125 14.4042C9.52473 14.4042 8.78581 14.2577 8.09569 13.9649C7.41211 13.6654 6.80989 13.2553 6.28906 12.7344C5.76822 12.2135 5.35807 11.6113 5.05859 10.9277C4.76562 10.2376 4.61913 9.4987 4.61913 8.71094Z"
        fill="#979797"
      />
    </>
  ),
  chevronDownFill: (
    <>
      <path
        d="M6.95634 8.39999C6.72989 8.40004 6.50807 8.46416 6.31652 8.58495C6.12498 8.70574 5.97152 8.87826 5.87387 9.08258C5.77623 9.2869 5.7384 9.51467 5.76474 9.73958C5.79108 9.9645 5.88053 10.1774 6.02274 10.3536L10.5995 16.0212C10.7683 16.2301 10.9816 16.3987 11.2239 16.5144C11.4662 16.6302 11.7314 16.6903 11.9999 16.6903C12.2685 16.6903 12.5336 16.6302 12.776 16.5144C13.0183 16.3987 13.2316 16.2301 13.4003 16.0212L17.9783 10.3536C18.1205 10.1774 18.21 9.9645 18.2363 9.73958C18.2627 9.51467 18.2248 9.2869 18.1272 9.08258C18.0296 8.87826 17.8761 8.70574 17.6846 8.58495C17.493 8.46416 17.2712 8.40004 17.0447 8.39999H6.95634Z"
        fill="black"
      />
    </>
  ),
  alert: (
    <>
      <path
        d="M9.99999 0C15.5228 0 20 4.47715 20 9.99998C20 15.5229 15.5228 19.9999 9.99999 19.9999C4.47715 19.9999 0 15.5229 0 9.99998C0 4.47715 4.47715 0 9.99999 0ZM9.99618 8.24929C9.48343 8.24962 9.06106 8.63587 9.00364 9.13318L8.99699 9.24979L9.00059 14.7505L9.00739 14.8672C9.06547 15.3643 9.48833 15.7501 10.0011 15.7498C10.5138 15.7493 10.9362 15.3631 10.9936 14.8658L11.0003 14.7493L10.9967 9.24848L10.9899 9.13188C10.9318 8.63466 10.5089 8.24895 9.99618 8.24929ZM10.0004 4.50033C9.30935 4.50033 8.74911 5.06057 8.74911 5.75165C8.74911 6.44274 9.30935 7.00297 10.0004 7.00297C10.6915 7.00297 11.2518 6.44274 11.2518 5.75165C11.2518 5.06057 10.6915 4.50033 10.0004 4.50033Z"
        fill="#0566D8"
      />
    </>
  ),
  employeeInfo: (
    <>
      <path d="M15.3334 17.6654H8.66671C6.66671 17.6654 5.33337 16.6654 5.33337 14.332V9.66536C5.33337 7.33203 6.66671 6.33203 8.66671 6.33203H15.3334C17.3334 6.33203 18.6667 7.33203 18.6667 9.66536V14.332C18.6667 16.6654 17.3334 17.6654 15.3334 17.6654Z" />
      <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" />
      <path opacity="0.4" d="M7.66663 10.332V13.6654" />
      <path opacity="0.4" d="M16.3334 10.332V13.6654" />
    </>
  ),
  payrollManagement: (
    <>
      <path d="M17.3333 9.4987V15.9987C17.3333 17.9987 16.14 18.6654 14.6666 18.6654H9.33329C7.85996 18.6654 6.66663 17.9987 6.66663 15.9987V9.4987C6.66663 7.33203 7.85996 6.83203 9.33329 6.83203C9.33329 7.24536 9.49994 7.6187 9.77327 7.89203C10.0466 8.16536 10.42 8.33203 10.8333 8.33203H13.1666C13.9933 8.33203 14.6666 7.6587 14.6666 6.83203C16.14 6.83203 17.3333 7.33203 17.3333 9.4987Z" />
      <path d="M14.6667 6.83203C14.6667 7.6587 13.9934 8.33203 13.1667 8.33203H10.8334C10.42 8.33203 10.0467 8.16536 9.77336 7.89203C9.50002 7.6187 9.33337 7.24536 9.33337 6.83203C9.33337 6.00536 10.0067 5.33203 10.8334 5.33203H13.1667C13.58 5.33203 13.9534 5.4987 14.2267 5.77203C14.5001 6.04537 14.6667 6.4187 14.6667 6.83203Z" />
      <path opacity="0.4" d="M9.33337 12.668H12" />
      <path opacity="0.4" d="M9.33337 15.332H14.6667" />
    </>
  ),
  addNewItem: (
    <>
      <path opacity="0.4" d="M6.5 12.5H18.5" />
      <path d="M12.5 18.5V6.5" height="0" />
    </>
  ),
  prorate: (
    <>
      <path d="M20.75 13.75C20.75 18.58 16.83 22.5 12 22.5C7.17 22.5 3.25 18.58 3.25 13.75C3.25 8.92 7.17 5 12 5C16.83 5 20.75 8.92 20.75 13.75Z" />
      <path opacity="0.4" d="M12 8.5V13.5" />
      <path opacity="0.4" d="M9 2.5H15" />
    </>
  ),
  updateEmployee: (
    <>
      <path
        d="M5.79429 22.36L5.7943 22.36L5.79123 22.3591C5.68652 22.3283 5.60413 22.2197 5.60909 22.0924C5.8362 19.4375 8.57387 17.2188 12.0001 17.2188C15.4286 17.2188 18.1599 19.4311 18.3919 22.1009C18.4018 22.2228 18.3329 22.3237 18.2128 22.358L18.2128 22.358L18.206 22.36C17.3378 22.6214 16.29 22.7488 15.0001 22.7488H9.00014C7.71032 22.7488 6.66252 22.6214 5.79429 22.36ZM6.23817 21.4257L6.09478 21.9452L6.62352 22.0494C7.3244 22.1874 8.10846 22.2488 9.00014 22.2488H15.0001C15.8918 22.2488 16.6759 22.1874 17.3768 22.0494L17.9055 21.9452L17.7621 21.4257C17.1547 19.2253 14.7114 17.7188 12.0001 17.7188C9.28886 17.7188 6.84557 19.2253 6.23817 21.4257Z"
        fill="#7B828E"
      />
      <path d="M15 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 19.28 3.14 21.35 5.86 22.12C6.08 19.52 8.75 17.47 12 17.47C15.25 17.47 17.92 19.52 18.14 22.12C20.86 21.35 22 19.28 22 15.5V9.5C22 4.5 20 2.5 15 2.5ZM12 14.67C10.02 14.67 8.42 13.06 8.42 11.08C8.42 9.10002 10.02 7.5 12 7.5C13.98 7.5 15.58 9.10002 15.58 11.08C15.58 13.06 13.98 14.67 12 14.67Z" />
      <path
        d="M11.9999 14.92C9.88756 14.92 8.16992 13.1954 8.16992 11.08C8.16992 8.96616 9.88607 7.25 11.9999 7.25C14.1138 7.25 15.8299 8.96616 15.8299 11.08C15.8299 13.1954 14.1123 14.92 11.9999 14.92ZM11.9999 7.75C10.1638 7.75 8.66992 9.24388 8.66992 11.08C8.66992 12.925 10.1626 14.42 11.9999 14.42C13.8372 14.42 15.3299 12.925 15.3299 11.08C15.3299 9.24388 13.8361 7.75 11.9999 7.75Z"
        fill="#7B828E"
      />
    </>
  ),
  disableEmployee: (
    <>
      <path d="M12 12.5C14.7614 12.5 17 10.2614 17 7.5C17 4.73858 14.7614 2.5 12 2.5C9.23858 2.5 7 4.73858 7 7.5C7 10.2614 9.23858 12.5 12 12.5Z" />
      <path
        opacity="0.4"
        d="M3.41 22.5C3.41 18.63 7.26 15.5 12 15.5C12.96 15.5 13.89 15.63 14.76 15.87"
      />
      <path d="M22 18.5C22 18.82 21.96 19.13 21.88 19.43C21.79 19.83 21.63 20.22 21.42 20.56C20.73 21.72 19.46 22.5 18 22.5C16.97 22.5 16.04 22.11 15.34 21.47C15.04 21.21 14.78 20.9 14.58 20.56C14.21 19.96 14 19.25 14 18.5C14 17.42 14.43 16.43 15.13 15.71C15.86 14.96 16.88 14.5 18 14.5C19.18 14.5 20.25 15.01 20.97 15.83C21.61 16.54 22 17.48 22 18.5Z" />
      <path d="M19.03 17.4414L16.92 19.5514" />
      <path d="M16.94 17.4609L19.06 19.5709" />
    </>
  ),
  exportFile: (
    <>
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12"
        strokeWidth="1.5"
      />

      <path d="M13 11.0008L21.2 2.80078" strokeWidth="1.5" />
      <path d="M21.9999 6.83V2H17.1699" strokeWidth="1.5" />
    </>
  ),
  pencil: (
    <>
      <path
        d="M21.7312 2.26884C20.706 1.24372 19.044 1.24372 18.0189 2.26884L16.8617 3.426L20.574 7.13831L21.7312 5.98116C22.7563 4.95603 22.7563 3.29397 21.7312 2.26884Z"
        fill="#0F172A"
      />
      <path
        d="M19.5134 8.19897L15.801 4.48666L3.65021 16.6375C3.03342 17.2543 2.58003 18.015 2.33101 18.851L1.53123 21.5359C1.45261 21.7998 1.52496 22.0856 1.71969 22.2803C1.91442 22.4751 2.2002 22.5474 2.46413 22.4688L5.14902 21.669C5.98499 21.42 6.74574 20.9666 7.36253 20.3498L19.5134 8.19897Z"
        fill="#0F172A"
      />
    </>
  ),
  play: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.6527C4.5 4.22656 6.029 3.32251 7.2786 4.00979L18.8192 10.3571C20.1144 11.0695 20.1144 12.9306 18.8192 13.6429L7.2786 19.9902C6.029 20.6775 4.5 19.7735 4.5 18.3473V5.6527Z"
        fill="#0F172A"
      />
    </>
  ),
} as const;
