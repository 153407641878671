import "react-toastify/dist/ReactToastify.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  ApolloProvider
} from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { ToastContainer } from "react-toastify";

import { client } from "./ApolloProvider";
import { AuthProvider } from "./context/Authcontext";
import DefaultLayout from "./routes";
import GlobalStyles from "./shared/GlobalStyles";


function App() {

  const googleClient = process.env.REACT_APP_GOOGLE_CLIENT_ID as string
  
  return (
    <GoogleOAuthProvider clientId={googleClient}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <DefaultLayout />
          <GlobalStyles />
          <ToastContainer autoClose={4000} />
        </AuthProvider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
