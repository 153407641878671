import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";

import { AuthContext } from "../../context/Authcontext";
import Button from "../../library/Button";
import SideBar from "../SideBar/SideBar";
import {
  StyledArticle,
  StyledDashboard,
  StyledHeader,
  StyledOptions,
  StyledUser,
} from "./Dashboard.styles";

const Dashboard = () => {
  const context = useContext(AuthContext);
  const userInfo = localStorage.getItem("CurrentUser");
  const userInitial = localStorage.getItem("userInitial");
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const butRef = useRef<HTMLButtonElement>(null);


  const logOut = () => {
    context?.logout();
  };

  useEffect(() => {
    const handleShow = (e: any) => {
      if (
        e.target !== butRef.current &&
        butRef.current &&
        !butRef.current.contains(e.target) &&
        optionsRef.current &&
        optionsRef.current.classList.contains("show")
      ) {
        setShowOptions(false);
      }
    };
    window.addEventListener("click", handleShow);
    return () => {
      window.removeEventListener("click", handleShow);
    };
  });

  return (
    <StyledDashboard>
      <SideBar />
      <main>
        <StyledHeader>
          <div
            className={`profile ${showOptions ? "show" : ""}`}
            ref={optionsRef}
          >
            <StyledUser
              onClick={() => {
                setShowOptions(!showOptions);
              }}
              ref={butRef}
            >
              <span className="name">{userInfo}</span>
              <span className="avatar">{userInitial}</span>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                <circle cx="6" cy="6" r="6" fill="white" fillOpacity="0.01" />
                <path
                  d="M3.19127 2.99922C2.79052 2.99922 2.55261 3.44709 2.77698 3.77915L5.67577 8.06935C5.87701 8.36719 6.31739 8.36172 6.51117 8.05898L9.25739 3.76878C9.47042 3.43598 9.23142 2.99922 8.83628 2.99922H3.19127Z"
                  fill="#1A1C1F"
                />
              </svg>
            </StyledUser>
            <div className="options_cont">
              <StyledOptions className="options">
                <ul>
                  <li>
                    <Button variant="text" onClick={logOut}>
                      Log Out
                    </Button>
                  </li>
                </ul>
              </StyledOptions>
            </div>
          </div>
        </StyledHeader>
        <StyledArticle>
          <Outlet />
        </StyledArticle>
      </main>
    </StyledDashboard>
  );
};

export default Dashboard;
