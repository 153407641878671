import * as React from "react";

import { StyledButton, StyledText } from "./Button.styles";
import { ButtonProps } from "./Button.types";

const Button: React.FC<ButtonProps> = ({
  variant = "solid",
  color,
  size = "md",
  isLoading,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  children,
  width,
  type = "button",
  margin,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled}
      size={size}
      width={width}
      variant={variant}
      color={color}
      onClick={onClick}
      data-loading={isLoading}
      type={type}
      aria-disabled={disabled}
      {...props}
    >
      {iconLeft && <span className="icon-left">{iconLeft}</span>}
      {isLoading ? (
        <StyledText>Loading</StyledText>
      ) : (
        <StyledText variant={variant}>{children}</StyledText>
      )}
      {iconRight && <span className="icon-right">{iconRight}</span>}
    </StyledButton>
  );
};

export default Button;
