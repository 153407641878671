import React, { lazy, Suspense, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthContext } from "../context/Authcontext";
import Dashboard from "../layout/Dashboard/Dashboard";
import { Loader } from "../library/Loader";
import ErrorBoundary from "./ErrorBoundary";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const SigninView = lazy(() => import("../views/SigninView"));
const UserManagementView = lazy(() => import("../views/UserManagementView"));
const ApprovalManagementView = lazy(
  () => import("../views/ApprovalManagementView")
);
const GiftcardManagementView = lazy(
  () => import("../views/GiftcardManagementView")
);
const MessagingManagementView = lazy(
  () => import("../views/MessagingManagementView")
);
const BillerManagementView = lazy(
  () => import("../views/BillerManagementView")
);
const BusinessManagementView = lazy(
  () => import("../views/BusinessManagementView")
);
const CbCustomerManagementView = lazy(
  () => import("../views/CbCustomerManagementView")
);

const PayrollManagementView = lazy(
  () => import("../views/PayrollManagementView")
);

const AccountingView = lazy(() => import("../views/AccountingManagement"));
const PostingView = lazy(() => import("../views/CbPostingView"));

const CbProductManagementView = lazy(
  () => import("../views/CbProductManagementView")
);

const CbReportingManagementView = lazy(
  () => import("../views/ReportingManagementView")
);

const FeeInterestView = lazy(() => import("../views/FeeInterestView"));

const AuditTrailView = lazy(() => import("../views/AuditTrailView"));
const IDVerificationView = lazy(() => import("../views/IdVerificationView"))

const MerchantVerificationView = lazy(() => import("../views/MerchantVerificationView"))

function DefaultLayout() {
  const context = useContext(AuthContext);

  useEffect(() => {
    context?.loadAuthUser();
  }, []);

  const isAuth = context?.isAuth;

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader data-testid="App" />} />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute isAuth={isAuth}>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route
              path="/user-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <UserManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/biller-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <BillerManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/approval-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <ApprovalManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/giftcard-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <GiftcardManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/messaging-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <MessagingManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/business-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <BusinessManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/reporting-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <CbReportingManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/logs/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <AuditTrailView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/id-verification/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <IDVerificationView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/fee-interest/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <FeeInterestView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/customer-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <CbCustomerManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/payroll-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <PayrollManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/account-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <AccountingView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/posting/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <PostingView />
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="/product-management/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <CbProductManagementView />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/merchant-verification/*"
              element={
                <PrivateRoute isAuth={isAuth}>
                  <Suspense fallback={<Loader />}>
                    <MerchantVerificationView />
                  </Suspense>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/signin"
            element={
              <PublicRoute isAuth={isAuth}>
                <Suspense fallback={<Loader />}>
                  <SigninView />
                </Suspense>
              </PublicRoute>
            }
          />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default DefaultLayout;
